import React, {Component, MouseEventHandler, ReactElement, useEffect, useState} from "react";
import Graph, {graphData, Network} from "react-graph-vis";
import axios, {AxiosResponse} from "axios";
import {Row, Col, Modal, Button, ButtonGroup, Spinner} from "react-bootstrap";

//const APIdomain = "http://localhost:8086"
const APIdomain = "https://graph.doclogica.com"

function getEtiologyColorsFromAPI(onSuccess: (data: [CategoryColor]) => void, onError: () => void) {
    axios.get<[CategoryColor]>(APIdomain+"/disease/etiology_colors")
        .then(function (response) {
            onSuccess(response.data)
        })
        .catch ( error => {
                onError()
            }
        )
}
function getSymptomColorsFromAPI(onSuccess: (data: [CategoryColor]) => void, onError: () => void) {
    axios.get<[CategoryColor]>(APIdomain+"/disease/symp_category_colors")
        .then(function (response) {
            onSuccess(response.data)
        })
        .catch ( error => {
                onError()
            }
        )
}
function getDataFromAPI(onSuccess: (data: graphData) => void, onError: () => void) {
    axios.get<graphData>(APIdomain+"/disease/cached_graph")
        .then(function (response) {
            onSuccess(response.data)
        })
    .catch ( error => {
        onError()
    }
    )
}
function getNodeInfoFromAPI(nodeID: number | null, onSuccess: (data: NodeInfo) => void, onError: () => void) {
    axios.get<NodeInfo>(APIdomain+"/disease/"+nodeID)
        .then(function (response) {
            onSuccess(response.data)
        })
        .catch ( error => {
                onError()
            }
        )
}
type LegendProps = {
    sympColors: CategoryColor[] | null
    dzColors: CategoryColor[] | null
}
function Legend({sympColors,dzColors}:LegendProps) {
    return (
        <>
            <Row>
                <Col sm>
                    <h6>Disease (circle)</h6>
                    <hr/>
                    {dzColors?.map( el =>
                            <p key={el.category} style={{color:el.color}}>{el.category}</p>
                    )}
                </Col>
                <Col sm>
                    <h6>Symptom (square)</h6>
                    <hr/>
                    {sympColors?.map( el =>
                        <p key={el.category} style={{color:el.color}}>{el.category}</p>
                    )}
                </Col>
            </Row>
        </>
    )
}

type NodeDescriptionProps = {
    id: number | undefined
    name: string | null | undefined,
    category: string | undefined,
    findings: string[]
    causes: string[]
}
function NodeDescription({id, name, category, findings, causes}:NodeDescriptionProps){

    let canCauseElement : ReactElement = <></>
    let canBeCausedByElement : ReactElement = <></>

    if (findings.length > 0) {
        canCauseElement =
            <>
            <h6 className={"text-start text-muted pt-4"}>Can cause</h6>
            <hr/>
            {
                findings.map(finding =>
                    <p key={finding} className={"text-muted text-start"}>{finding}</p>
                )
            }
            </>
    }
    if (causes.length > 0) {
        canBeCausedByElement =
            <>
                <h6 className={"text-start text-muted pt-4"}>Can be caused by</h6>
                <hr/>
                {
                    causes.map(cause =>
                        <p key={cause} className={"text-muted text-start"}>{cause}</p>
                    )
                }
            </>
    }
    return (
        <>
            <h6 className={"text-start text-muted"}>Name</h6>
            <hr/>
            <p className={"text-muted text-start"}>{name}</p>
            <h6 className={"text-start text-muted pt-4"}>Category</h6>
            <hr/>
            <p className={"text-muted text-start"}>{category}</p>
            {canCauseElement}
            {canBeCausedByElement}
        </>
    )
}

interface CategoryColor {
    category: string
    color: string
}
interface NodeInfo {
    id: number
    name: string
    acuity: number
    category: string
    findings: Finding[]
    causes: string[]
}
interface Finding {
    name: string,
    id: number //negative is symptom, positive is disease
}
export default function DiseaseGraph() {

    const [data,setData] = useState<graphData|null>(null)
    const [sympCategoryColors,setSympCategoryColors] = useState<[CategoryColor]|null>(null)
    const [dzCategoryColors,setDzCategoryColors] = useState<[CategoryColor]|null>(null)
    const [selectedNodeID,setSelectedNodeID] = useState<number|null>(null)
    const [selectedNodeInfo,setSelectedNodeInfo] = useState<NodeInfo|null>(null)
    const [network,setNetwork] = useState<Network|null>(null)
    const [showNodeInfoModal, setShowNodeInfoModal] = useState(false)
    const [showLegendModal, setShowLegendModal] = useState(false)
    const handleCloseNodeInfoModal = () => setShowNodeInfoModal(false)
    const handleShowNodeInfoModal = () => setShowNodeInfoModal(true)
    const handleCloseLegendModal = () => setShowLegendModal(false)
    const handleShowLegendModal = () => setShowLegendModal(true)

    function zoomIn() {
        const currentScale = network?.getScale()
        // @ts-ignore
        const changedScale = currentScale*1.5
        network?.moveTo({scale: changedScale})
    }
    function zoomOut() {
        const currentScale = network?.getScale()
        // @ts-ignore
        let changedScale = currentScale/1.5
        if (changedScale <= 0) { changedScale = 0.01 }
        network?.moveTo({scale: changedScale})
    }

    useEffect(() => {
        getDataFromAPI(function (response) {
                setData(response)
            },
            function () {
                console.log("an error occurred")
            }
        )
        getEtiologyColorsFromAPI(function (response) {
                setDzCategoryColors(response)
            },
            function () {
                console.log("an error occurred")
            }
        )
        getSymptomColorsFromAPI(function (response) {
                setSympCategoryColors(response)
            },
            function () {
                console.log("an error occurred")
            }
        )
    }, [])
    useEffect(() => {
        getNodeInfoFromAPI(selectedNodeID, function (response) {
                setSelectedNodeInfo(response)
                handleShowNodeInfoModal()
            },
            function () {
                console.log("an error occurred")
            }
        )
    }, [selectedNodeID])

    const options = {
        nodes: {
            shape: "dot",
            scaling: {
                min: 10,
                max: 30,
            },
            font: {
                size: 12,
                face: "Tahoma"
            },
            fixed: true
        },
        edges: {
            color: {
                inherit: 'to'
            }
        },
        physics: false /*{
            stabilization: false,
            barnesHut: {
                gravitationalConstant: -80000,
                springConstant: 0.001,
                springLength: 200,
            },
        }*/,
        interaction: {
            tooltipDelay: 200,
            hideEdgesOnDrag: false,
        },
        height: "100%",
        width: "100%"
    }

    const events = {
        select: function(event:any) {
            var { nodes, edges } = event
            if (nodes.length > 0 && data) {
                // @ts-ignore
                setSelectedNodeID(nodes[0])
            } else {
                setSelectedNodeInfo(null)
            }
        }
    }

    if (data) {
        let findings = selectedNodeInfo?.findings.map(name => name.name)
        if (findings === undefined) {
            findings = []
        }
        let causes = selectedNodeInfo?.causes
        if (causes === undefined) {
            causes = []
        }
        let nodeInfoModalTitle : string = "Disease Details"
        if (selectedNodeInfo !== null && selectedNodeInfo?.id !== undefined) {
           if (selectedNodeInfo.id < 0) {
               nodeInfoModalTitle = "Symptom Details"
           }
        }

        return (
            <>
                <Row style={{height:"100%", position:"relative"}} className={""}>
                    <Col md={12} style={{height:"100%"}} className={""}>
                        <Graph
                            options={options}
                            graph={data}
                            events= {events}
                            getNetwork={network => {
                                setNetwork(network)
                            }}
                        />

                        <ButtonGroup className={"m-4"} style={{position:"absolute",top:0,left:0,backgroundColor:"white"}}>
                            <Button variant="outline-secondary" onClick={() => zoomIn()}>+</Button>
                            <Button variant="outline-secondary" onClick={() => zoomOut()}>-</Button>
                        </ButtonGroup>
                        <Button variant="outline-secondary" onClick={() => handleShowLegendModal()} className={"m-4"} style={{position:"absolute",top:0,right:0,backgroundColor:"white"}}>Legend</Button>
                    </Col>
                </Row>
                <CustomModal title={nodeInfoModalTitle} body={<NodeDescription id={selectedNodeInfo?.id} name={selectedNodeInfo?.name} findings={findings} causes={causes} category={selectedNodeInfo?.category}/>} handleCloseModal={handleCloseNodeInfoModal} showModal={showNodeInfoModal}/>
                <CustomModal title={"Legend"} body={<Legend dzColors={dzCategoryColors} sympColors={sympCategoryColors}/>} handleCloseModal={handleCloseLegendModal} showModal={showLegendModal}/>
            </>
        )
    } else {
        return (
            <div className="h-100 d-flex justify-content-center">
                <Spinner animation="border" role="status" className={"my-auto text-secondary"}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
    }
}
type CustomModalProps = {
    title : string
    body : ReactElement,
    showModal : boolean,
    handleCloseModal : () => void
}
function CustomModal({title,body,showModal,handleCloseModal}:CustomModalProps){
    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
