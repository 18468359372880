export enum links {
    applestore = "https://apps.apple.com/app/doclogica/id1363604747",
    googlestore = "https://play.google.com/store/apps/details?id=com.elkproductions.doclogica",
    lupus = "https://app.doclogica.com/ex1",
    covid = "https://app.doclogica.com/ex3",
    ddx = "https://app.doclogica.com/ex2",
    nephrotic = "https://app.doclogica.com/ex4",
    signin = "https://app2.doclogica.com",
    apiexample = "https://api2.doclogica.com/example"
}
