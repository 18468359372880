import React, {ReactElement, useEffect, useRef} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Navbar, Nav, Stack} from 'react-bootstrap';
import SplashView from "./sections/home/splash_view";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import BlogView, {BlogPost} from "./sections/blog/blog_view";
import {blogPosts} from "./sections/blog/blog_posts";
import DiseaseGraph from "./disease_graph";
import TutorialVideo from "./sections/tutorial/tutorial_video";
import {links} from "./links";

enum NavBarLocation {
    home,
    blog,
    blogPost
}

function App() {

    const fifthViewRef = useRef<HTMLDivElement>(null)
    const executeScrollToFifthView = () => fifthViewRef?.current?.scrollIntoView()

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/tutorial" element={
                        <>
                        <CustomNavbar path = {NavBarLocation.blog} handlePricingLinkClick={executeScrollToFifthView}/>
                            <Container fluid style={{height:"100vh"}}>
                                <TutorialVideo/>
                            </Container>
                        </>
                    }>
                    </Route>
                    <Route path="/graph/" element={
                        <>
                            <Container fluid style={{height:"100vh"}}>
                                <DiseaseGraph/>
                            </Container>
                        </>
                    }>
                    </Route>
                    <Route path="/" element={
                        <>
                            <CustomNavbar path = {NavBarLocation.home} handlePricingLinkClick={executeScrollToFifthView}/>
                            <SplashView fifthViewRef={fifthViewRef}/>
                            <CustomFooter/>
                        </>
                    }>
                    </Route>
                    <Route path="/blog" element={
                        <>
                            <CustomNavbar path ={NavBarLocation.blog} handlePricingLinkClick={executeScrollToFifthView}/>
                            <BlogView/>
                            <CustomFooter/>
                        </>
                    }>
                    </Route>
                    {blogPosts.map((post) =>
                        <Route path={"/blog/" + post.link} element={
                            <>
                                <CustomNavbar path ={NavBarLocation.blogPost} handlePricingLinkClick={executeScrollToFifthView}/>
                                <BlogPost auth={post.auth} link={post.link} title={post.title} body={post.body} img={post.img} date={post.date} description={post.description}/>
                                <CustomFooter/>
                            </>
                        }/>
                    )}
                </Routes>
            </Router>
        </div>
    );
}

export default App;

function loadShadowOnScroll(){
    window.addEventListener('scroll',(e)=>{
        const nav = document.querySelector('.navbar');
        if(window.scrollY>0){
            // @ts-ignore
            nav.classList.add("add-shadow");
        }else{
            // @ts-ignore
            nav.classList.remove("add-shadow");
        }
    });
}
type CustomNavbarProps = {
    handlePricingLinkClick: () => void
    path : NavBarLocation
}
function CustomNavbar({handlePricingLinkClick, path}:CustomNavbarProps) {

    useEffect( () =>
            loadShadowOnScroll()
        , [])

    let rightSideElements : ReactElement = <></>
    if (path === NavBarLocation.home) {
        rightSideElements =
            <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                    <Nav.Link className="" onClick={handlePricingLinkClick}>
                        Plans
                    </Nav.Link>
                    <Nav.Link href="/blog">Blog</Nav.Link>
                    <Nav.Link style={{color:"#0d6efd"}} href={links.signin}>Sign In</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </>
    }

    return (
        <Navbar sticky="top" className="bg-white" expand={"sm"}>
            <Container fluid>
                <Navbar.Brand href="/" style={{fontWeight:500}}>
                    <img
                        alt=""
                        src="/logo192.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top me-3"
                        style={{borderRadius:100}}
                    />
                    docLogica
                </Navbar.Brand>
                {rightSideElements}
            </Container>
        </Navbar>
    )
}

function CustomFooter() {
    return (
        <Container fluid className="bg-light mt-auto">
            <Stack direction="horizontal" className="p-3 text-muted" gap={3}>
                <div>© docLogica Medical Inc. 2021</div>
                <a style={{textDecoration: "none"}} href="https://app.doclogica.com/html/privacypolicy.html" className={"ms-auto text-muted"}>Privacy Policy</a>
                <div className="vr"/>
                <a style={{textDecoration: "none"}} href="https://app.doclogica.com/html/termsofuse.html" className={"text-muted"}>Terms of Use</a>
                <div className="vr"/>
                <a style={{textDecoration: "none"}} href="mailto:contact@doclogica.com" className={"text-muted"}>Contact</a>
            </Stack>
        </Container>
    )
}
