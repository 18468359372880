import {BlogPostProps} from "./blog_view";
import {Col, Row} from "react-bootstrap";
import React from "react";

export let blogPosts : Array<BlogPostProps>
blogPosts = [
    {title: "How to Use a Likelihood Ratio",
    link: "lrs",
    auth: "Michael Garfinkle, MD FRCPC",
    img: "/math-chalkboard.jpeg",
    date: "November 1, 2021",
    description: "While likelihood ratios are taught extensively in medical education, how can we actually use these, practically, at the bedside?",
    body:
        <>
            <p>As a student, I became frustrated when I was told that such-and-such test was "good" and I should use
                it. What did good mean? Is a good test always right? I like statistics and percentages, but these
                tests were presented with a hazy diagnostic foundation that I found unsatisfying. It jarred with the
                evidence based medicine that I was, in the same breath, being told I should practice. When I
                discovered the power of likelihood ratios (LRs) things started to make much more sense.</p>
            <p>Following a brief statistical interlude, this post will teach you how to 1) determine the diagnostic
                strength of common tests and 2) how to use likelihood ratios in real world situations.</p>
            <h4>Statistical interlude</h4>
            <p>To use likelihood ratios effectively you need to understand these 5 points:</p>

            <p>1. Every patient that presents with a complaint has a probability of having any number of diseases.
                This is called the pre-test probability.</p>

            <p>2. We perform tests on patients to increase or decrease the probability of them having each possible
                disease. A test’s ability to increase or decrease the probability of a certain disease is given by
                the likelihood ratio. Likelihood ratios can be calculated for positive and negative test results
                using the sensitivity and specificity.</p>

            <p className={"text-center"}>Positive likelihood ratio = Sensitivity / (1 – Specificity)</p>

            <p className={"text-center"}>Negative likelihood ratio = (1 – Sensitivity) / Specificity</p>

            <p>Whereas sensitivity and specificity tell us how good a test is when the patient already has
                (sensitivity) or does not have (specificity) the disease in question, likelihood ratios tell us how
                much a positive or negative test result affects the likelihood of a disease when we do not know if
                they have it or not! This is why they are more important, in clinical practice, than sensitivity and
                specificity</p>

            <p>3. A positive test result usually increases a disease’s post-test probability. The greater the
                positive likelihood ratio, the greater the post-test likelihood of disease. Positive likelihood
                ratios range from one to infinity. A negative test result usually decreases a disease’s post-test
                probability. The smaller the negative likelihood ratio, the less likely the post-test probability of
                disease is. Negative likelihood ratios range from zero to one.</p>

            <p>4. No test is perfect and whether a test influences our clinical decision making in a particular
                situation depends on the severity of the disease and the pre-test probability, not necessarily how
                big or small its likelihood ratios are.</p>

            <p>5. Tests can only be used one after one another in specific situations. Whether they can be "added"
                together depends on whether they are dependent upon one another.</p>

            <p><i>
                <hr/>
                For example, hearing rales on exam increases a persons chance of having a pneumonia, as does
                egophony. If an individual has both does that mean that we can use the first test’s post-test
                probability as the second’s pre-test probability? Not necessarily: these variables could be
                dependent on one another. Most patients with egophony might also have rales (meaning they are
                basically the same test) which would make it erroneous to add them.</i></p>
            <hr/>

            <p>When can we add tests? Often we must rely on our intuition. An x-ray has little to do with a
                stethoscope finding so we would probably be able to use the post-test probability of hearing rales
                for pneumonia as the pre-test probability for the test of an x-ray finding a consolidation. However,
                the only truly accurate way to use combinations of tests is to use a decision rule that is derived
                from studies that have ascertained the accuracy of amalgamated tests. The <a
                    href={"https://www.aafp.org/afp/2007/0815/p560.html"}>Heckerling score for pneumonia</a> is such
                a decision rule.</p>

            <h4>The problem – and the solution?</h4>
            <p>This sounds great in principle, but in practice we rarely speak in post-test probabilities and LRs. I
                think this is because they are hard to remember, there is non-trival math involved to use them,
                there are so many of them, and there was, until recently, no easy way to access them. That is why I
                created docLogica. It contains hundreds of likelihood ratios and pre-test probabilities. It will
                also calculate the post-test probability for you! The following examples will demonstrate how you to
                can use this resource to approach some medical cases in a probabilistic and rational manner. The
                screenshots found below are from the iOS application.</p>
            <h5>Case #1</h5>
            <Row>
                <Col className={"my-auto"}>
                    <p>A 30 year old woman presents with dysuria and urinary frequency. She thinks she may be having
                        a UTI. You know that her pre-test probability of UTI is high, about 50%. You know that no
                        quickly available testing (negative urinalysis would only decrease her probability to about
                        15%) would decrease this probability below your treatment threshold, which you have set at
                        ~10% given the low risk of most first line antibiotics. You elect to forego any testing and
                        prescribe empiric antibiotics.</p>
                </Col>
                <Col className={"text-center"}>
                    <img src={"/blog-ex1.png"}
                         style={{height: "80vh", filter: "drop-shadow(15px 10px 8px #666666)"}}/>
                </Col>
            </Row>
            <h5>Case #2</h5>
            <Row>
                <Col className={"my-auto"}>
                    <p>Let’s shift gears to demonstrate point #4 from the statistical interlude. A fifty-five year
                        old woman presents with a new cough, runny nose and sore throat. Her grand-daughter has been
                        sick all week with similar symptoms. A family friend recently died from a pulmonary embolism
                        and she is quite concerned that she might be having one. A d-dimer was ordered to alleviate
                        her concerns and came back positive. A CT-PE study was then done which showed a single
                        segmental PE. Case closed, right?</p>
                    <p>You’re gut is probably questioning this conclusion, and this is why. First, the presentation
                        is atypical for a pulmonary embolus. She’s over 50, so the PERC rule is not negative, but
                        her Well’s score is low, giving her a 6% pre-test probability of pulmonary embolus. Her
                        pre-test probability would probably be even lower because the Well’s score was validated in
                        an ER population suspected of having PE and most physicians would not suspect a PE in this
                        case. However, we will allow this conservative overestimation and use it as our pre-test
                        probability.</p>
                    <p>CT-PE is a very good rule-in test, but no test is perfect (unless it’s the gold standard) and
                        its average likelihood ratio is 24 (high, but still a far way from infinity). This gives a
                        post-test probability for PE of about 60%. This also means that the chance that this woman
                        has a diagnosis other than PE is 40%! That’s almost 50-50!!</p>
                    <p>So, before we start her on lifelong anticoagulation, we may consider further testing (e.g.
                        the gold standard, angiography) or getting her close follow up with a specialist to consider
                        her case further. In this case, despite how ‘good’ of a test a CT-PE is, it still leaves us
                        with a lot of uncertainty due to the low pre-test probability in this case.</p>
                </Col>
                <Col className={"text-center"}>
                    <img src={"/blog-ex2.png"}
                         style={{height: "80vh", filter: "drop-shadow(15px 10px 8px #666666)"}}/>
                </Col>
            </Row>
            <h4>Conclusion</h4>
            <p>There are thousands of tests out there. Sometimes it feels like the hospital is a test factory with
                poor quality control. However, selecting the most useful tests for a given situation can both save
                time time and avoid unnecessary and potentially harmful tests. I hope this article has made you
                aware of the important role that likelihood ratios can play in everyday practice. By quantitatively
                understanding what a test does to a patient’s diagnostic probabilities we can relay information to
                patients to help them confidently participate in the decision making process.</p>

            <p>While you are investigating the wonderful world of likelihood ratios, please consider giving
                docLogica a try.</p>
        </>
    },
    {title: "Do Doctors Remember Everything?",
    link: "remember",
    auth: "Michael Garfinkle, MD FRCPC",
    img: "/doghouse.png",
    date: "November 14, 2021",
    description: "Doctors are expected to memorize an impossible amount of diagnostic information. How can this be rectified?",
    body:
        <>
            <p>The general approach to the differential diagnosis has remained unchanged since the dawn of modern medicine. A doctor sees a patient, gets some initial information, and then creates a list of what they think are the most likely possibilities - with some less likely things that could kill the patient if missed added - to rule in or rule out. This list of possibilities is created by comparing the present patient to others that the doctor has seen and/or querying his/her memory for disease patterns that they have read in textbooks.</p>

            <p>This methodology can lead to tremendous error for a variety of reasons. First, a doctor can neglect to consider disease possibilities that they have not seen or read about before. This happens often as there are thousands of diseases and it is just humanly impossible to remember them all. Furthermore, a single disease can present differently, and another common error is to not consider a less common presentation for a common disease.</p>

            <p>For example, I was the nephrologist on call a number of years ago and was called to see a dialysis patient, Mr. P, who was having severe acute abdominal pain. The nurses had drawn some labs and he had a very elevated white count. His abdominal skin itself was acutely painful to even light touch. He also had a reticular rash over the area.</p>

            <p>I thought it was sepsis. He had a white count and I see a lot of sepsis in dialysis patients. His skin could be the source of infection and the cause of the pain. Or maybe it was SIRS from another infective source and he had mottling of the abdomen and ischemic pain from a low flow state. So I gave him antibiotics and admitted him to the hospital. But over the next few days he wasn't getting better and the abdominal pain remained excruciating despite high dose opioids.</p>

            <p>What did Mr. P end up having? It was calciphylaxis. His pain improved with appropriate therapy, but he was on inappropriate therapy and in pain on the ward for days before I was able to reexamine and modify my original diagnosis. Why had I not initially made the correct diagnosis? 1) I had never seen abdominal calciphylaxis before, I had only seen it in the legs and 2) I had always thought calciphylaxis presented with either necrotic skin lesions or subcutaneous nodules; I had never seen other presentations, such as a reticular rash.</p>

            <p>Was I a bad doctor for not knowing these symptoms of calciphylaxis? Should I have clued in sooner? No, I’m human and it’s just impossible to remember all this stuff. Thousands of diseases multiplied by thousands of symptoms equals tens of thousands of unique data points that must fit into one, error prone, usually sleep deprived and crunched for time brain. Diagnostic errors will inevitably occur and this is partly why almost 150,000 patients die or are seriously harmed per year in the US alone from diagnostic error.</p>
            <Row>
                <Col className={"my-auto"}>
                    <p>What’s the solution?  Somewhere to keep all this stuff. A searchable electronic database of disease symptoms and probabilities that can be queried at the point of care to help us humans out. I have built such a database, by combing the primary literature and inputting all these data points over the past seven year. The database is called docLogica and is available on the web and on smartphone apps. It also contains test accuracies (likelihood ratios) as it’s equally impossible to remember the accuracies of all the tests! I cannot imagine diagnostic medicine evolving or improving without such a point of care tool. Give it a look and help bring diagnostic medicine into the 21st century.</p>
                </Col>
                <Col className={"text-center"}>
                    <img src={"/blog-ex3.png"}
                         style={{height: "80vh", filter: "drop-shadow(15px 10px 8px #666666)"}}/>
                </Col>
            </Row>
        </>
    }
]