import React from 'react'

export default function TutorialVideo() {
    return (
        <div className="embed-responsive embed-responsive-4by3 flex d-flex justify-content-center" style={{height:"100%", width:"100%"}}>
            <iframe className="embed-responsive-item h-75 w-75 mt-5" src="https://www.youtube.com/embed/qb4YRqF5gmc"
                    allowFullScreen></iframe>
        </div>
    )
}

