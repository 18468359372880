import React, {ReactElement} from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {blogPosts} from "./blog_posts";

function BlogCard({title,auth,img,body,date,link,description}: BlogPostProps){
    return (
        <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={img} />
            <Card.Body>
                <Card.Subtitle className="mb-2 text-muted text-start">{date}</Card.Subtitle>
                <Card.Title className={"text-start"}>{title}</Card.Title>
                <Card.Text className={"text-start"}>
                    {description}
                </Card.Text>
                <a href={"blog/" + link} className={"stretched-link"}/>
            </Card.Body>
        </Card>
    )
}

export default function BlogView() {
    return (
        <>
            <Container style={{minHeight:"100vh",justifyContent:"space-between"}}>
                <h1 className={"display-3"}>doc<strong>Blog</strong>ica</h1>
                <hr className={"mb-5"}/>
                <Row xs={1} md={2} className="g-4">
                {blogPosts.map((post) =>
                    <Col className="d-flex justify-content-center">
                        <BlogCard auth={post.auth} body={<></>} date={post.date} title={post.title} img={post.img} link={post.link} description={post.description}/>
                    </Col>
                )}
                </Row>
            </Container>
        </>
    )
}

export type BlogPostProps = {
    title: string,
    link: string,
    auth: string,
    img: string,
    date: string,
    description: string,
    body: ReactElement
}
export function BlogPost({title,auth,img,body,date,link,description}:BlogPostProps) {
    return (
        <>
            <h1 className="display-5 pb-2">{title}</h1>
            <h5 className="pb-2 text-muted">{auth +  " - " + date}</h5>
            <img src={img} style={{width:"50vw"}}/>
            <Container style={{minHeight:"100vh"}} className={"pt-4 lead text-start"}>{body}</Container>
        </>
    )
}